import React from "react"
import classes from "./OurTeamCarouselItem.module.scss"
import Image from "../../../image"

const OurTeamCarouselItem = ({ photo, name, position }) => {
  return (
    <div className={classes.card}>
      <Image src={photo} className={classes.image} draggable={false} />
      <h4 className={classes.name}>{name}</h4>
      <span className={classes.position}>{position}</span>
    </div>
  )
}

export default OurTeamCarouselItem
