import React from "react"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import OurTeamCarouselItem from "./OurTeamCarouselItem/OurTeamCarouselItem"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 8000, min: 4000 },
    items: 3,
    slidesToSlide: 1,
  },
  LargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}
const defaultSlides = JSON.parse(`[
  {
    "name": "Alex Khorunzhyi",
    "position": "CEO",
    "photo": "ourTeam/img-1.jpg"
  },
  {
    "name": "Bogdan Shtybin",
    "position": "CTO",
     "photo": "ourTeam/img-2.jpg"
  },
  {
    "name": "Arthur Goncharenko",
    "position": "Head of HR Department",
     "photo": "ourTeam/img.jpg"
  },
  {
    "name": "Ivan Karpus",
    "position": "Mobile Team Lead",
    "photo": "ourTeam/img-5.jpg"
  },
  {
    "name": "Yaroslav Petrov",
    "position": "Back-End Team Lead",
    "photo": "ourTeam/img-10.jpg"
  },
  {
    "name": "Eugene Yakovlev",
    "position": "Back-End Team Lead",
    "photo": "ourTeam/img-4.jpg"
  },
  {
    "name": "Marina Chulkova",
    "position": "Design Team Lead",
    "photo": "ourTeam/img-12.jpg"
  },
  {
    "name": "Igor Pilipenko",
    "position": "Project Manager",
    "photo": "ourTeam/img-6.jpg"
  },
  {
    "name": "Mikhail Oros",
    "position": "Project Manager",
    "photo": "ourTeam/img-3.jpg"
  },
  {
    "name": "Samir Ismailov",
    "position": "Project Manager",
    "photo": "ourTeam/img-13.jpg"
  },
  {
    "name": "Maksym Khalyavin",
    "position": "Project Manager",
    "photo": "ourTeam/img-7.jpg"
  },
  {
    "name": "Alexander Shevchenko",
    "position": "Project Manager",
    "photo": "ourTeam/img-8.jpg"
  },
  {
    "name": "Volodymyr Golemba",
    "position": "Sales Manager",
    "photo": "ourTeam/img-9.jpg"
  },
  {
    "name": "Vladislav Baranov",
    "position": "Sales Manager",
    "photo": "ourTeam/img-11.jpg"
  }
]`)
const OurTeamCarousel = ({ className, ourTeam = defaultSlides }) => {
  return (
    <div className={className}>
      <MultiCarousel
        setup={responsive}
        sectionTitle={"Our Team"}
        buttonGroup={true}
      >
        {ourTeam.map((item, index) => (
          <OurTeamCarouselItem
            key={index}
            photo={item.photo}
            name={item.name}
            position={item.position}
          />
        ))}
      </MultiCarousel>
    </div>
  )
}

export default OurTeamCarousel
