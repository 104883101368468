import React, { memo } from "react"
import { Link } from "gatsby"
import Image from "src/components/image"
import classes from "./PreviewCases.module.scss"
import Marquee from "react-fast-marquee"
import "./OverrideTicker.scss"

const PreviewCases = ({ cases }) => {
  return (
    <Link to={`/portfolio`} aria-label="Go to portfolio">
      <Marquee
        className="tickerImagesContainer"
        gradient={false}
        pauseOnHover={true}
        speed={40}
        loop={0}
        delay={0}
        children={cases.map(item => (
          <div className={classes.casesLink} key={item.id}>
            <Image
              src={item.screenPreview}
              alt={"Go to Portfolio"}
              className={classes.previewImage}
            />
          </div>
        ))}
      />
    </Link>
  )
}

export default memo(PreviewCases)
